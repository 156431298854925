@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: white;
  color: black;
  margin: 0;
  font-family: "Montserrat", "Courier New", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.slick-dots li.slick-active button:before {
  color: white !important;
}

.slick-dots button:before {
  color: white !important;
}
.youtube-video {
  aspect-ratio: 16 / 9;
  width: 100%;
}
